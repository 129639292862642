import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import MEDIA from '../styles/media';
import headerBg from '../images/header-bg-darker.svg';
import headerBgMobile from '../images/header-bg-darker-mobile.svg';

export default class HeroModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    legal: PropTypes.string,
    image: PropTypes.shape({
      title: PropTypes.string,
      alt: PropTypes.string,
      src: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
    }),
  };

  render() {
    const { title, subTitle, legal, image } = this.props;
    return (
      <HeroStyles bg={headerBg} bgMobile={headerBgMobile}>
        <div className="content">
          <h1>
            {title}
            <sup>*</sup>
          </h1>
          <div className="sub-title">{subTitle}</div>
          <div
            className="legal"
            dangerouslySetInnerHTML={{
              __html: legal,
            }}
          />
        </div>
        <div className="image">
          <Image
            fluid={image.src.childImageSharp.fluid}
            title={image.title}
            alrt={image.alt}
            outerWrapperClassName="bg-image"
            className="bg-image"
          />
        </div>
      </HeroStyles>
    );
  }
}

// noinspection JSUnresolvedVariable
const HeroStyles = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${MEDIA.DESKTOP`
    flex-direction: column-reverse;
  `};
  .content {
    width: 50%;
    flex: 0px 0px 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    background: url(${props => props.bg}) 0 0 no-repeat;
    background-size: cover;
    ${MEDIA.DESKTOP`
      background: url(${props => props.bgMobile}) 50% 50% no-repeat;
      background-size: cover;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    `};
    h1 {
      margin: 0 4rem .5rem 1.5rem;
      font-family: ${props => props.theme.fonts.secondary};
      color: #fff;
      font-size: 3.25rem;
      line-height: 1.35;
      letter-spacing: -1.1px;
      font-weight: 400;
      max-width: 34rem;
      ${MEDIA.DESKTOP`
        margin: 3rem 1.5rem 1rem;
        font-size: 2rem;
        max-width: none;
      `};
      sup {
        font-size: 75%;
      }
    }
    .sub-title {
      margin: 0 9rem 4rem 1.5rem;
      max-width: 30rem;
      //min-width: 30rem;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 1.625rem;
      letter-spacing: -0.6px;
      text-align: left;
      color: #ffffff;
      ${MEDIA.DESKTOP`
        margin: 0rem 1.5rem 3rem;
        font-size: 1.25rem;
        line-height: 1.2;
        max-width: none;
        min-width: unset;
      `};
    }
    .legal {
      margin: 0 8rem 1rem 1.5rem;
      max-width: 30rem;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.2;
      letter-spacing: -0.3px;
      color: #fff;
      a {
        color: #fff;
      }
      ${MEDIA.DESKTOP`
        margin: 0 1.5rem 3rem;
        max-width: none;
        font-size: 13px;
      `};
    }
  }
  .image {
    width: 50%;
    flex: 0px 0px 50%;
    position: relative;
    min-height: 30rem;

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    ${MEDIA.DESKTOP`
      min-height: unset;
      width: 100%;
      
      .bg-image {
        position: relative;
      }
    `};
  }
`;

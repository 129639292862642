import React, { PureComponent } from 'react';
import styled from 'styled-components';
import MEDIA from '../styles/media';

export default class FooterModule extends PureComponent {
  render() {
    return (
      <FooterStyles>
        <div>
          © {new Date().getFullYear()} CMH Services, Inc. All rights reserved.
        </div>
        <ul>
          <li>
            | <a
            rel="noopener noreferrer"
            href="https://www.claytonhomes.com/legal">Legal</a>
          </li>
          <li>
            | <a
            rel="noopener noreferrer"
            href="https://www.claytonhomes.com/privacy">Privacy</a>
          </li>
          <li>
            | <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
              }}
            >
              Do Not Sell or Share My Personal Information
            </a>

          </li>
        </ul>
      </FooterStyles>
    );
  }
}

const FooterStyles = styled.footer`
  min-height: 4rem;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 0.75rem;
  color: #6d6e71;
  ${MEDIA.PHONE`
    flex-direction: column;
    align-items: center;
  `}
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    ${MEDIA.PHONE`
      flex-direction: column;
      align-items: center;
    `}
  }
  li {
    margin: .5rem 0 .5rem 1rem;
    padding: 0;
  }
  a {
    margin: 0 0.5rem;
    text-decoration: none;
    color: #6d6e71;
  }
`;

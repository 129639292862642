import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import Image from 'gatsby-image';
import btnArrow from '../images/btn-arrow.svg';
import MEDIA from '../styles/media';

export default class GalleryModule extends Component {
  static propTypes = {
    galleryClick: PropTypes.func,
    gallery: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        title: PropTypes.string,
        body: PropTypes.string,
        image: PropTypes.shape({
          title: PropTypes.string,
          alt: PropTypes.string,
          src: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.object,
            }),
          }),
        }),
      })
    ).isRequired,
  };

  state = {
    slideIndex: 0,
  };

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  handleSlideChange(current, next) {
    this.setState({
      slideIndex: next,
    });
    this.props.galleryClick();
  }

  nextSlide() {
    this.slider.slickNext();
  }

  prevSlide() {
    this.slider.slickPrev();
  }

  render() {
    const { gallery } = this.props;
    const selectedItem = gallery[this.state.slideIndex];
    return (
      <>
        <GalleryStyles>
          <div className="slider">
            <SlickSliderStyles>
              <Slider
                ref={r => (this.slider = r)}
                arrows={false}
                dots={false}
                beforeChange={this.handleSlideChange}
              >
                {gallery
                  .map(item => item.image)
                  .map((image, index) => (
                    <Image
                      key={index}
                      fluid={image.src.childImageSharp.fluid}
                      title={image.title}
                      alt={image.alt}
                    />
                  ))}
              </Slider>
            </SlickSliderStyles>
          </div>
          <div className="content">
            <div className="button-group">
              <button
                className="square-button"
                onClick={() => this.prevSlide()}
              >
                <img className="icon flip" src={btnArrow} alt="Button Arrow" />
              </button>
              <button
                className="square-button"
                onClick={() => this.nextSlide()}
              >
                <img className="icon" src={btnArrow} alt="Button Arrow" />
              </button>
            </div>
            <div className="content-text">
              <div className="category">{selectedItem.category}</div>
              <div className="title">{selectedItem.title}</div>
              <div className="body">{selectedItem.body}</div>
            </div>
          </div>
        </GalleryStyles>
        <BlueLine />
      </>
    );
  }
}

const GalleryStyles = styled.section`
  display: flex;
  position: relative;

  ${MEDIA.TABLET`display:block;`};
  .slider {
    width: 65%;
    ${MEDIA.TABLET`width: 100%;`};
  }
  .content {
    width: 35%;
    ${MEDIA.TABLET`width: 100%;`};

    display: flex;
    flex-direction: column;
    justify-content: center;

    .button-group {
      margin: 0 1rem 2rem 3rem;
      ${MEDIA.TABLET`
          margin: -2.25rem 0 2rem;
          z-index: 10;
      `};
      display: flex;
      .square-button {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.5rem;
        height: 4.5rem;
        ${MEDIA.DESKTOP`
          width:4rem;
          height:4rem;
        `};
        background-color: ${props => props.theme.colors.secondary};
        border: none;
        box-shadow: none;
        outline: none;
        cursor: pointer;

        .icon {
          transition: all 250ms ease-out;
          &.flip {
            transform: rotate(180deg);
          }
        }
      }
    }

    .content-text {
      max-width: 20rem;
      margin: 0 1rem 0 3rem;

      ${MEDIA.TABLET`margin: 0 1.5rem 4rem`};

      .category {
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 2.8px;
        color: ${props => props.theme.colors.primary};
      }
      .title {
        margin-bottom: 1.375rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 2.25rem;
        font-weight: 300;
        line-height: 1;
        color: #414042;
      }
      .body {
        padding-bottom: 4rem;
        ${MEDIA.DESKTOP`
          padding-bottom: 0;
          margin-bottom:0;
        `};
        margin-bottom: 2rem;
        font-family: ${props => props.theme.fonts.primary};
        font-size: 1rem;
        line-height: 1.69;
        letter-spacing: -0.3px;
        color: #6d6e71;
      }
    }
  }
`;

const BlueLine = styled.div`
    display: block;
    width: 100%;
    height: 1.5rem;
    margin-top: -0.25rem;
    background-color: ${props => props.theme.colors.secondary};
`;

const SlickSliderStyles = styled.div`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;

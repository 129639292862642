import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import nxtSlant from '../images/nxt-slant.svg';
import Slider from 'react-slick';
import btnArrow from '../images/btn-arrow.svg';
import MEDIA from '../styles/media';

export default class FeaturesModule extends Component {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    featureClick: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.shape({
          title: PropTypes.string,
          alt: PropTypes.string,
          src: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.object,
            }),
          }),
        }),
      })
    ),
  };

  state = {
    slideIndex: 0,
  };

  constructor(props) {
    super(props);
    this.slider = React.createRef();
    this.handleSelection = this.handleSelection.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
  }

  handleSelection(index) {
    this.slider.slickGoTo(index);
  }

  handleSlideChange(current, next) {
    this.setState({
      slideIndex: next,
    });
    this.props.featureClick();
  }

  nextSlide() {
    this.slider.slickNext();
  }

  prevSlide() {
    this.slider.slickPrev();
  }

  render() {
    const { title, subTitle, items } = this.props;
    const slideIndex = this.state.slideIndex;
    const currentItem = items[slideIndex];
    return (
      <FeaturesStyles>
        <div className="header">
          <h3 className="title">{title}</h3>
          <p className="sub-title">{subTitle}</p>
        </div>
        <div className="container">
          <ul className="list">
            {items.map((item, index) => (
              <li
                className={`list-item ${index === slideIndex && 'active'}`}
                key={item.name}
                onClick={() => this.handleSelection(index)}
              >
                <div className="icon">
                  <img src={nxtSlant} alt="Nxt List Icon" />
                </div>
                {item.name}
              </li>
            ))}
          </ul>
          <div className="mobile-info">
            <div className="name">{currentItem.name}</div>
            <div className="pager">
              {slideIndex + 1} / {items.length}
            </div>
          </div>
          <div className="gallery">
            <SlickSliderStyles>
              <Slider
                ref={r => (this.slider = r)}
                arrows={false}
                dots={false}
                beforeChange={this.handleSlideChange}
              >
                {items.map(item => item.image).map((image, index) => (
                  <Image
                    key={index}
                    fluid={image.src.childImageSharp.fluid}
                    title={image.title}
                    alt={image.alt}
                  />
                ))}
              </Slider>
            </SlickSliderStyles>
            <div className="button-group">
              <button
                className="square-button"
                onClick={() => this.prevSlide()}
              >
                <img className="icon flip" src={btnArrow} alt="Button Arrow" />
              </button>
              <button
                className="square-button"
                onClick={() => this.nextSlide()}
              >
                <img className="icon" src={btnArrow} alt="Button Arrow" />
              </button>
            </div>
          </div>
        </div>
        <div className="ring-legal">
          Trademarks of companies other than Clayton are the property of those other companies.
        </div>
      </FeaturesStyles>
    );
  }
}

const FeaturesStyles = styled.section`
  margin: 0 0 4rem;
  ${MEDIA.TABLET`margin: 0 0 2rem`};
  .header {
    margin: 4rem 0;
    padding: 0 1.5rem;
    .title {
      margin: 0 0 1.5rem;
      text-align: center;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1;
      color: #414042;
      ${MEDIA.TABLET`font-size: 2rem;`};
    }
    .sub-title {
      margin: 0 auto 1rem;
      max-width: 48rem;
      text-align: center;
      font-family: ${props => props.theme.fonts.primary};
      font-size: 1rem;
      line-height: 1.69;
      letter-spacing: -0.3px;
      color: #6d6e71;
    }
  }

  .container {
    margin: 2rem auto 0;
    max-width: 1088px;
    display: flex;
    ${MEDIA.TABLET`
      flex-direction: column-reverse;
    `};
    .list {
      width: 40%;
      padding: 0 3rem;
      list-style: none;
      ${MEDIA.TABLET`display: none;`};

      .list-item {
        position: relative;
        margin: 0 0 1.5rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 1.125rem;
        line-height: 2;
        color: #414042;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;

        .icon {
          display: none;
          padding: 0.25rem;
          img {
            width: 100%;
          }
        }

        &.active {
          font-weight: 600;
          letter-spacing: -0.5px;
          color: ${props => props.theme.colors.primary};
          .icon {
            display: block;
            position: absolute;
            top: 0.25rem;
            left: -3rem;
          }
        }
      }
    }
    .mobile-info {
      display: none;
      margin: 0 0 2rem;
      ${MEDIA.TABLET`
        display: flex;
        flex-direction: column;
        align-items: center;
      `};
      .name {
        margin: 0 0 1rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 1.125rem;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: center;
        color: #414042;
      }
      .pager {
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 2.8px;
        text-align: center;
        color: #6d6e71;
      }
    }
    .gallery {
      width: 60%;
      position: relative;
      display: flex;
      flex-direction: column;
      ${MEDIA.TABLET`width: 100%;`};

      .button-group {
        margin: -2.5rem auto 1.5rem;
        z-index: 10;
        display: flex;
        .square-button {
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4.5rem;
          height: 4.5rem;
          ${MEDIA.DESKTOP`
            width:4rem;
            height:4rem;
        `};
          background-color: ${props => props.theme.colors.secondary};
          border: none;
          box-shadow: none;
          outline: none;
          cursor: pointer;

          .icon {
            transition: all 250ms ease-out;
            &.flip {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .ring-legal {
    margin: 0 auto;
    padding: 0 2rem;
    max-width: 62rem;
    font-family: ${props => props.theme.colors.secondary};
    font-size: .75rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -0.3px;
    text-align: left;
    color: #414042;
    ${MEDIA.TABLET`
      text-align: center;
    `};
  }
`;

const SlickSliderStyles = styled.div`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;

/*******************************
 * COLORS
 *******************************/
export const colors = {
  primary: '#0075c9',
  secondary: '#95d4e8',
  danger: '#f0504b',
  grayDark: '#414042',
  grayMedium: '#6d6e71',
  grayLight: '#a2a2a3',
  grayLighter: '#d6d6d6',
  grayLightest: '',
};

/*******************************
 * FONTS
 *******************************/
export const fonts = {
  // Default font for the body
  primary:
    '"Source Sans Pro", -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol", sans-serif',
  // Default font for headers
  secondary:
    'acumin-pro, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol", sans-serif',
};

/*******************************
 * Borders
 *******************************/
export const borders = {
  radius: '0',
  color: colors.grayLighter,
};

export default {
  colors,
  fonts,
  borders,
};

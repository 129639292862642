import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoVector from '../images/nxt-full-color.svg';
import phoneIcon from '../images/phone-icon.svg';
import MEDIA from '../styles/media';

export default class NavModule extends PureComponent {
  static propTypes = {
    logo: PropTypes.shape({
      title: PropTypes.string,
      alt: PropTypes.string,
    }),
    phone: PropTypes.string,
    onPhoneClick: PropTypes.func,
  };

  render() {
    const { logo, phone, onPhoneClick } = this.props;
    return (
      <NavStyles>
        <div className="logo">
          <img src={logoVector} title={logo.title} alt={logo.alt} />
        </div>
        <a
          className="phone"
          href={`tel:${phone}`}
          onClick={() => onPhoneClick()}
        >
          <img src={phoneIcon} title="Phone Icon" alt="Phone Icon" />
          {phone}
        </a>
      </NavStyles>
    );
  }
}

const NavStyles = styled.nav`
  height: 4.25rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA.TABLET`padding: 0 1rem;`} .logo {
    max-width: 4rem;
    img {
      width: 100%;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    font-size: 1.375rem;
    line-height: 1.3;
    letter-spacing: 0.4px;
    font-family: ${props => props.theme.fonts.secondary};
    text-decoration: none;
    color: ${props => props.theme.colors.primary};

    img {
      margin-right: 0.5rem;
      width: 1rem;
    }
  }
`;

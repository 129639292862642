import React, { PureComponent } from 'react';
import styled from 'styled-components';

export default class LegalFooter extends PureComponent {
  render() {
    return (
      <StyledLegalFooter>
        <div className="container" id="legal">
          <div className="item">
            <div className="num">*</div>
            <p className="body">
              Advertised starting sales prices are for the home only. Delivery
              and installation costs are not included unless otherwise stated.
              Starting prices shown on this website are subject to change, see
              your local Authorized NXT Retailers for current and specific home
              and pricing information. Sales price does not include other costs
              such as taxes, title fees, insurance premiums, filing or recording
              fees, land or improvements to the land, optional home features,
              optional delivery or installation services, wheels and axles,
              community or homeowner association fees, or any other items not
              shown on your Sales Agreement, Retailer Closing Agreement and
              related documents (your SA/RCA). If you purchase a home, your
              SA/RCA will show the details of your purchase. Homes available at
              the advertised sales price will vary by retailer and state.
              Artists’ renderings of homes are only representations and actual
              home may vary. Floor plan dimensions are approximate and based on
              length and width measurements from exterior wall to exterior wall.
              We invest in continuous product and process improvement. All home
              series, floor plans, specifications, dimensions, features,
              materials, and availability shown on this website are subject to
              change
            </p>
          </div>
        </div>
      </StyledLegalFooter>
    );
  }
}

const StyledLegalFooter = styled.section`
  padding: 2rem;
  background-color: #f5f5f5;
  .container {
    max-width: 1244px;
    margin: 2rem auto 0;
  }
  .item {
    display: flex;
    .num {
      flex: 0px 0px 1rem;
      margin-right: .25rem;
      font-family: ${props => props.theme.fonts.primary};
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: -0.5px;
      color: #a2a2a3;
    }
    .body {
      margin: 0 0 2rem;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 0.75rem;
      line-height: 1.42;
      letter-spacing: -0.3px;
      color: #6d6e71;
    }
  }
`;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import 'normalize.css/normalize.css';
import theme from '../styles/theme';
import { Helmet } from 'react-helmet';
import icon from '../images/icon.png';

export default class Layout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };
  render() {
    const { children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <LayoutStyles>
          <Helmet
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${icon}` },
            ]}
          >
          {/* <!-- Osano Privacy Script --> */}
            <script src="https://cmp.osano.com/AzZd2RTbLigwQ5KJW/fc5c932e-d137-4fcf-ba8f-d9531da1e2ef/osano.js" />
          {/* <!-- End Osano Privacy Script --> */}
          </Helmet>
          {children}
        </LayoutStyles>
      </ThemeProvider>
    );
  }
}

const LayoutStyles = styled.main`
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.grayDark};
  font-family: ${props => props.theme.fonts.secondary};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1rem;
    font-family: ${props => props.theme.fonts.secondary};
  }

  .osano-cm-widget {
    display: none;
  }

  .osano-cm-dialog {
    display: none;
  }
`;

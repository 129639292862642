import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import MEDIA from '../styles/media';
import nxtIcon from '../images/nxt-icon.svg';
import houseIcon from '../images/house.svg';
import bedIcon from '../images/bed.svg';
import bathIcon from '../images/bath.svg';
import { ModalConsumer } from '../store/modalProvider';

export default class FloorPlanModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    legal: PropTypes.string,
    floorPlanClick: PropTypes.func,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.string,
        beds: PropTypes.string,
        baths: PropTypes.string,
        image: PropTypes.shape({
          title: PropTypes.string,
          alt: PropTypes.string,
          src: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.object,
            }),
          }),
        }),
      })
    ).isRequired,
  };
  render() {
    const { title, items, legal, floorPlanClick } = this.props;
    return (
      <FloorPlanStyles>
        <div className="container">
          <h3 className="title">{title}</h3>
          <Row>
            {items.map((item, index) => (
              <Col key={index}>
                <div className="floorplan-card">
                  <ModalConsumer>
                    {({ showModal }) => (
                      <div
                        className="floorplan-image"
                        onClick={() => {
                          floorPlanClick(item.name);
                          showModal(() => (
                            <WhiteBg>
                              <Image
                                className="image"
                                fluid={item.image.src.childImageSharp.fluid}
                                title={item.image.title}
                                alt={item.image.alt}
                              />
                            </WhiteBg>
                          ));
                        }}
                      >
                        <Image
                          className="image"
                          fluid={item.image.src.childImageSharp.fluid}
                          title={item.image.title}
                          alt={item.image.alt}
                        />
                      </div>
                    )}
                  </ModalConsumer>
                  <div className="floorplan-content">
                    <div className="floorplan-name">
                      <img src={nxtIcon} alt="NXT Icon" className="icon" />
                      <h4>{item.name}</h4>
                    </div>
                    <div className="floorplan-size">
                      <img src={houseIcon} alt="House Icon" className="icon" />
                      <div className="text">{item.size}</div>
                    </div>
                    <div className="floorplan-beds">
                      <img src={bedIcon} alt="Bed Icon" className="icon" />
                      <div className="text">{item.beds} beds</div>
                    </div>
                    <div className="floorplan-baths">
                      <img src={bathIcon} alt="Bath Icon" className="icon" />
                      <div className="text">{item.baths} baths</div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className="floorplan-legal">{legal}</div>
        </div>
      </FloorPlanStyles>
    );
  }
}

const FloorPlanStyles = styled.section`
  display: block;
  background-color: #f5f5f5;
  
  .container {
    max-width: 1248px;
    margin: 0 auto;
    padding: 3rem 1.5rem 4rem;
  }
  .title {
    margin: 1rem 0;
    color: #414042;
    font-size: 2.25rem;
    font-family: ${props => props.theme.fonts.secondary};
    line-height: 1;
    font-weight: 300;
    text-align: center;
    ${MEDIA.TABLET`font-size: 2rem;`};
  }
  .floorplan-legal {
    font-family: ${props => props.theme.fonts.secondary};
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.42;
    letter-spacing: -0.3px;
    text-align: center;
    color: #6d6e71;
  }
  .floorplan-card {
    margin-bottom: 2rem;
    .floorplan-image {
      position: relative;
      cursor: zoom-in;
      min-height: 15rem;
      margin: 0 1rem;
      display: flex;
      .image {
        flex: 1;
        height: 100%;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .floorplan-content {
      background-color: #fff;
      padding: 1.5rem 1rem 2rem;
    }
    .floorplan-name {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      .icon {
        width: 1rem;
        object-fit: contain;
        margin-right: 0.75rem;
      }
      h4 {
        margin-bottom: 0.25rem;
        font-family: ${props => props.theme.fonts.secondary};
        font-size: 1.625rem;
        font-weight: 300;
        line-height: 1;
        color: #414042;
      }
    }
    .floorplan-size,
    .floorplan-beds,
    .floorplan-baths {
      display: flex;
      align-items: center;
      margin: 0 0 0.25rem;
      .icon {
        width: 1.875rem;
        margin-left: 1.625rem;
      }
      .text {
        margin: 0.5rem 0 0 1rem;
        font-family: ${props => props.theme.fonts.primary};
        font-size: 1rem;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.3px;
        color: #6d6e71;
      }
    }
  }
`;

const WhiteBg = styled.div`
  background-color: #fff;
  padding: 2rem;
  max-width: 1248px;
  margin: 0 auto;
  ${MEDIA.TABLET`padding:1rem;`};
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 calc(25% - 1.5rem);
  ${MEDIA.DESKTOP`flex: 0 0 calc(50% - 1rem)`} ${MEDIA.PHONE`flex: 1 1 100%`};
`;

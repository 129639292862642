import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';
import MEDIA from '../styles/media';

export default class BuildingFacilityModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    image: PropTypes.shape({
      title: PropTypes.string,
      alt: PropTypes.string,
      src: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          fluid: PropTypes.object,
        }),
      }),
    }),
  };
  render() {
    const { title, body, image } = this.props;
    return (
      <BuildingFacilityStyles>
        <div className="content">
          <div className="content-wrapper">
            <div className="title">{title}</div>
            <div className="divider" />
            <div className="body">{body}</div>
          </div>
        </div>
        <div className="image">
          <Image
            outerWrapperClassName="bg-image"
            className="bg-image"
            fluid={image.src.childImageSharp.fluid}
            title={image.title}
            alt={image.alt}
          />
        </div>
      </BuildingFacilityStyles>
    );
  }
}

const BuildingFacilityStyles = styled.section`
  display: flex;
  ${MEDIA.TABLET`flex-direction: column-reverse;`};
  background-color: #f5f5f5;

  .content {
    flex: 5;
    display: flex;
    align-items: center;
    .content-wrapper {
      margin: 1rem auto 3rem;
      padding: 1.5rem;
      max-width: 26rem;
      ${MEDIA.TABLET`max-width: unset;`};
    }
    .title {
      margin-bottom: 1.5rem;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1;
      color: #414042;
    }
    .divider {
      margin-bottom: 1.5rem;
      width: 3rem;
      height: 3px;
      background-color: ${props => props.theme.colors.secondary};
    }
    .body {
      font-family: ${props => props.theme.fonts.primary};
      font-size: 1rem;
      line-height: 1.69;
      letter-spacing: -0.3px;
      color: #6d6e71;
      white-space: pre-line;
    }
  }

  .image {
    flex: 7;
    position: relative;
    min-height: 36rem;

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    ${MEDIA.TABLET`
      min-height: unset;
      
      .bg-image {
        position: relative;
      }
    `};
  }
`;

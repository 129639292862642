import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import playButton from '../images/play-button-triangle.svg';
import MEDIA from '../styles/media';
import { ModalConsumer } from '../store/modalProvider';

export default class ClaytonBuiltModule extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    body: PropTypes.string,
    videoLeadIn: PropTypes.string,
    videoTitle: PropTypes.string,
    videoWistiaId: PropTypes.string,
    videoClick: PropTypes.func
  };

  videoContent = () => (
    <VideoContainer>
      <iframe
        title="video"
        src={`https://fast.wistia.net/embed/iframe/${this.props.videoWistiaId}`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </VideoContainer>
  );

  render() {
    const { title, subTitle, body, videoLeadIn, videoTitle, videoClick } = this.props;
    return (
      <ModalConsumer>
        {({ showModal }) => (
          <ClaytonBuiltStyles>
            <div className="header">
              <h2 className="title">{title}</h2>
              <div className="sub-title">{subTitle}</div>
            </div>
            <div className="content">
              <div className="body">{body}</div>
              <div className="video">
                <div className="icon-container">
                  <ButtonAnimation
                    className="circle"
                    onClick={() => {
                      showModal(this.videoContent);
                      videoClick();
                    }}
                  >
                    <img className="icon" src={playButton} alt="play button" />
                  </ButtonAnimation>
                </div>
                <div className="text-container">
                  <div className="lead-in">{videoLeadIn}</div>
                  <div className="title">{videoTitle}</div>
                </div>
              </div>
            </div>
          </ClaytonBuiltStyles>
        )}
      </ModalConsumer>
    );
  }
}

const ClaytonBuiltStyles = styled.section`
  margin: 4rem auto 8rem;
  ${MEDIA.TABLET`
    margin: 2rem 0;
 `};
  padding: 0 1.5rem;
  max-width: 1248px;

  .header {
    margin: 0 0 4rem;
    border-bottom: 1px solid #95d4e8;

    ${MEDIA.TABLET`
      margin: 0 0 2rem;
     `};

    .title {
      margin: 0 0 1.5rem;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: 2.25rem;
      font-weight: 300;
      line-height: 1;
      text-align: center;
      color: #414042;
      ${MEDIA.TABLET`
        font-size: 2rem;
     `};
    }
    .sub-title {
      margin: 0 auto 3rem;
      max-width: 776px;
      font-family: ${props => props.theme.fonts.primary};
      font-size: 1.5rem;
      line-height: 1.46;
      letter-spacing: -0.5px;
      text-align: center;
      color: #6d6e71;
      ${MEDIA.TABLET`
        font-size: 1.5rem;
        margin-bottom: 2rem;
     `};
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    ${MEDIA.DESKTOP`
      flex-direction: column;
    `};

    .body {
      width: 55%;
      font-family: ${props => props.theme.fonts.primary};
      font-size: 1rem;
      line-height: 1.69;
      letter-spacing: -0.3px;
      color: #6d6e71;
      ${MEDIA.DESKTOP`
        width: 100%;
        margin-bottom: 2rem;
      `};
    }
    .video {
      width: 38%;
      display: flex;
      align-items: center;
      ${MEDIA.DESKTOP`
        width: 100%;
        margin-bottom: 2rem;
      `};
      .icon-container {
        width: 8rem;
        height: 8rem;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: rgba(149, 212, 232, 0.3);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle {
          width: 5rem;
          height: 5rem;
          flex-grow: 0;
          flex-shrink: 0;
          background-color: #95d4e8;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;
          &:focus {
            outline: none;
          }
          .icon {
            margin-left: 0.5rem;
            width: 1.5rem;
          }
        }
      }
      .text-container {
        margin: 0 2rem;
        ${MEDIA.TABLET`
            margin: 0 0 0 2rem;
         `};
        .lead-in {
          font-family: ${props => props.theme.fonts.secondary};
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 1.75;
          letter-spacing: 2.8px;
          color: ${props => props.theme.colors.primary};
          text-transform: uppercase;
        }
        .title {
          font-family: ${props => props.theme.fonts.secondary};
          font-size: 1.625rem;
          font-weight: 300;
          line-height: 1.38;
          color: #414042;
          ${MEDIA.DESKTOP`
            font-size: 1.125rem;
          `};
        }
      }
    }
  }
`;

const VideoContainer = styled.div`
  margin: 5rem auto;
  max-width: 1288px;
  ${MEDIA.TABLET`margin: 1rem;`};
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ButtonAnimation = posed.button({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  },
  hover: {
    scale: 1.05,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.12)',
  },
  press: {
    scale: 1.025,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.06)',
  },
});

export const addToDataLayer = (event) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
};

export default {
  addToDataLayer
}
